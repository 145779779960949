import { ActivationQueryT, LoginQueryT, SignupQueryT } from 'types/auth';
import HttpService from './http.service';
import { getToken, removeToken, setToken } from './token.service';

export type TokenT = {
  access: string;
  refresh: string;
};

const AuthService = {
  login: async (req: LoginQueryT) => {
    const { data }: { data: TokenT } = await HttpService().post(
      '/login/',
      req
    );
    return data;
  },

  activation: async (req: ActivationQueryT) => {
    const { data }: { data: TokenT } = await HttpService().get(
      `/user/activate/${req.uid}/${req.token}/`,
    );
    return data;
  },

  signup: async (req: SignupQueryT) => {
    const { data }: { data: TokenT } = await HttpService().post('/users/', req);
    return data;
  },

  logout: async () => {
    const { data } = await HttpService().post(`auth/logout/`);
    return data;
  },

  refresh: async () => {
    const { refreshToken } = getToken();
    try {
      const { data }: { data?: TokenT } = await HttpService().post(
        '/token/refresh/',
        {
          refresh: refreshToken,
        }
      );

      return data;
    } catch (e) {
      return removeToken();
    }
  },
};

export default AuthService;
