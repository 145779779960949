import { AllOrdersT } from "services/orders.service";
import { formateDate } from "utils";
import styles from './OrdersCard.module.scss'
import clsx from "clsx";
type Props = {
    orders?: AllOrdersT[];
}

export const OrdersCard: React.FC<Props> = ({ orders }) => {
    return (
        <div className={styles.wrapper}>
            {orders && orders?.map((order) => (
                <div key={order.id} className={styles.card}>
                    <div className={clsx(styles.status, styles[`${order.status}`])}></div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Status:
                        </div>
                        <div className={styles.value}>
                            {order.status}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Service:
                        </div>
                        <div className={styles.value}>
                            {order.service}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Period:
                        </div>
                        <div className={styles.value}>
                            {order.period}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Quantity:
                        </div>
                        <div className={styles.value}>
                            {order.quantity}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Service option:
                        </div>
                        <div className={styles.value}>
                            {order.service_option}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Total price:
                        </div>
                        <div className={styles.value}>
                            {order.total_price}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Created:
                        </div>
                        <div className={styles.value}>
                            {formateDate(order.created_at)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Completed:
                        </div>
                        <div className={styles.value}>
                            {formateDate(order.completed)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
