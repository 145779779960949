import {
  StartPage,
  RegistrationPage,
  ProfilePage,
  NoMatchPage,
  LoginPage,
  ErrorPage,
  AuthRedirectPage,
  OrdersPage,
  TopupPage,
  HelpPage,
} from 'pages';
import { RouteNames } from './routeNames';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { ProfileLayout } from 'layouts/ProfileLayout';
import { useAppSelector } from 'lib/useAppSelect';
import { PrimaryLayout } from 'layouts/PrimaryLayout';

type PrivateRouteProps = {
  children: ReactElement;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isLogin } = useAppSelector((state) => state.authState);
  const location = useLocation();
  const authPath = [RouteNames.LOGIN, RouteNames.REGISTRATION];
  const isExcludePath = authPath.includes(location.pathname as RouteNames);

  if (isLogin && isExcludePath) {
    return <Navigate to="/" />;
  } else if (!isLogin && !isExcludePath) {
    return <Navigate to="/login" />;
  }

  return <PrimaryLayout>{children}</PrimaryLayout>;
};

type PublicRouteProps = {
  children: ReactElement;
};

export const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isLogin } = useAppSelector((state) => state.authState);

  if (isLogin) {
    return <Navigate to="/" />;
  }

  return children;
};

export const Routers = () => {
  const routes = useRoutes([
    {
      path: RouteNames.START,
      element: (
        <PrivateRoute>
          <ProfileLayout/>
        </PrivateRoute>
      ),
      children: [
        { path: RouteNames.START, element: <StartPage /> },
        { path: RouteNames.ORDERS, element: <OrdersPage /> },
        { path: RouteNames.TOPUP, element: <TopupPage /> },
        { path: RouteNames.PROFILE, element: <ProfilePage /> },
        { path: RouteNames.HELP, element: <HelpPage /> },
        { path: RouteNames.ERROR, element: <ErrorPage /> },
        { path: RouteNames.NOMATCH, element: <NoMatchPage /> },
        // Новый маршрут для динамических страниц заказов
        { path: `${RouteNames.ORDERS}/:id/:type`, element: <OrdersPage /> },
      ],
    },
    {
      path: "/activate/:id/:secret",
      element: <AuthRedirectPage />
    },
    {
      path: RouteNames.LOGIN, element:
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
    },
    {
      path: RouteNames.REGISTRATION, element:
        <PublicRoute>
          <RegistrationPage />
        </PublicRoute>
    },
    { path: RouteNames.ERROR, element: <ErrorPage /> },
    { path: RouteNames.NOMATCH, element: <NoMatchPage /> },
  ]);

  return routes;
};
