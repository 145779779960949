import React from 'react';
import { Center, SegmentedControl } from '@mantine/core';
import { IconType } from 'react-icons';
import styles from './CustomSegmentedControl.module.scss';

type Props = {
    segments?: {
        value: string;
        label: string;
        icon?: IconType;
    }[] | null;
    selectedValue: string;
    onChange: (value: string) => void;
};

export const CustomSegmentedControl: React.FC<Props> = ({ segments, selectedValue, onChange }) => {
    return (
        <>
            {
                segments ?
                    <SegmentedControl
                        data={segments?.map((segment) => ({
                            value: segment.value,
                            label: (
                                <Center style={{ gap: 10 }}>
                                {/* //   { segments?.icon &&
                                // >
                                //    <segment.icon
                                //         className={`${styles.icon} ${selectedValue === segment.value ? styles.icon__active : ''}`}
                                //     />
                                // } */}
                                    <span className={`${styles.icon} ${selectedValue === segment.value ? styles.label__active : ''}`}>{segment.label}</span>
                                </Center>
                            ),
                        }))}
                        value={selectedValue}
                        onChange={onChange}
                        classNames={{
                            root: styles.root,
                            label: styles.label,
                        }}
                    />
                    : null}
        </>
    );
};
