
import { API_URL } from 'consts';
import { getToken } from './token.service';
import axios from 'axios';

export const URL = `${API_URL}/api/v1/`;

export const HttpService = () => {
  if (!getToken().accessToken) {
    return axios.create({
      withCredentials: true,
      baseURL: URL,
      headers: {
        accept: 'application/json',
      },
    });
  }
  return axios.create({
    withCredentials: true,
    baseURL: URL,
    headers: {
      Authorization: `Bearer ${getToken().accessToken}`,
      accept: 'application/json',
    },
  });
};

export default HttpService;
