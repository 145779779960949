import { Button as MantineButton } from '@mantine/core';
import styles from './Button.module.scss';
import clsx from 'clsx';

type Props = {
    onClick?: () => void;
    content?: string | JSX.Element;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    classNames?: "black" 
}

export const Button: React.FC<Props> = ({ onClick, content, type = "button", disabled, classNames="" }) => {
    return (
        <MantineButton 
            classNames={{ root: clsx(styles.button, styles[classNames]) }} 
            disabled={disabled}
            onClick={type === "button" && onClick ? onClick : undefined}
            type={type}
        >
            {content}
        </MantineButton>
    )
}
