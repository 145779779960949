import styles from './Start.module.scss';
import { OrdersTable } from "./OrdersTable";
import { useOrdersAllQuery } from 'lib/orders/useOrdersQuery';
import { useAppSelector } from 'lib/useAppSelect';
import { OrdersCard } from './OrdersCard';

export const Start: React.FC = () => {
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const { data: orders } = useOrdersAllQuery();

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                My Services
            </div>
            {!isMobile ? <OrdersTable orders={orders} /> : <OrdersCard orders={orders} />}
        </div>
    )
}
