import HttpService from './http.service';

export type ServicesT = {
  id?: number;
  name?: string;
};

export type CategoriesT = {
  categories: string[];
};

export type CategoriesTypeT = {
  id: number;
  period?: string;
  category?: string;
  discount_percentage?: number;
  discounted_price?: number;
  points?: string[];
  price_per_unit?: string;
  required_field?: string[];
  interval?: any;
};

export type CalcPriceT = {
  service_option_id: number;
  quantity: number;
};

const Services = {
  servicesList: async () => {
    const { data }: { data: ServicesT[] } =
      await HttpService().get('service/services/');
    return data;
  },

  servicesCategories: async (service_id: string) => {
    const { data }: { data: { categories: string[] } } =
      await HttpService().get(`service/services/${service_id}/categories/`);
    return data;
  },
  servicesCategoryType: async (service_id: string, category?: string) => {
    const { data }: { data: CategoriesTypeT[] } = await HttpService().get(
      `service/services/${service_id}/categories/${category}/`
    );
    return data;
  },
  servicesCalculatePrice: async (req: CalcPriceT) => {
    const { data }: { data: { total_price: number } } =
      await HttpService().post(`service/calculate-price/`, req);
    return data;
  },
};

export default Services;
