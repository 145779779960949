import { useAppSelector } from "lib/useAppSelect";
import { useDeviceTypeIdentifier } from "lib/useDeviceTypeIdentifier";


type PrimaryLayoutT = {
  children: React.ReactNode;
};

export const PrimaryLayout: React.FC<PrimaryLayoutT> = ({ children }) => {
  useDeviceTypeIdentifier();
  return <>{children}</>;
}
