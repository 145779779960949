import { RouteNames } from "navigate/routeNames"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NoMatchPage: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(RouteNames.START)
    }, [])
    return (
        <></>
    )
}
