import { Registration } from 'feature';
import { PrimaryLayout } from '../../layouts/PrimaryLayout/PrimaryLayout';

export const RegistrationPage: React.FC = () => {
  return (
      <PrimaryLayout>
        <Registration/>
      </PrimaryLayout>
  );
}
