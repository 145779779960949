import HttpService from './http.service';

export type UsersT = {
  id: number;
  email: string;
  balance?: number;
}

const UserServices = {
  usersMe: async () => {
    const { data }: { data: UsersT } =
      await HttpService().get('users/me/');
    return data;
  },
};

export default UserServices;
