import { Input as Field } from '@mantine/core';
import React from 'react';
import styles from './Input.module.scss';

type Props = {
    placeholder: string;
    label: string;
    required?: boolean;
    type?: "password" | "text" | "email" | "number";
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // Add onFocus prop
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;   // Add onBlur prop
    autoFocus?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
    ({ label, placeholder, type = "text", required = false, value, onChange, onFocus, onBlur, autoFocus }, ref) => {
        return (
            <Field.Wrapper classNames={{ label: styles.label }} label={label} description="" error="" withAsterisk={required}>
                <Field
                    autoFocus={autoFocus}
                    type={type}
                    classNames={{ input: styles.input, section: styles.section }}
                    placeholder={placeholder}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus} // Pass onFocus to the underlying input
                    onBlur={onBlur}   // Pass onBlur to the underlying input
                />
            </Field.Wrapper>
        );
    }
);

Input.displayName = 'Input';
