import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button, Input } from "components";
import { CategoriesTypeT } from "services/services.service";
import styles from './FormProduct.module.scss';
import { Modal, Slider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
// import { FloatingIndicator, UnstyledButton } from '@mantine/core';
import { useState } from "react";
import { useOrdersCreateQuery } from "lib/orders/useOrdersQuery";
import toast from "react-hot-toast";
import { useUpdatePrice } from "hook/debouncedQuantity";
type Props = {
    closeAll: () => void
    product?: CategoriesTypeT | null;
    type_id?: string;
};

type FormProductInputs = {
    [key: string]: string;
    quantity: string;
    notes: string;
}

// const periods = ["Hour", "Day", "Week", "Month"];

export const FormProduct: React.FC<Props> = ({ product, closeAll, type_id }) => {
    const { control, handleSubmit, watch, formState: { errors } } = useForm<FormProductInputs>();
    const [opened, { open, close }] = useDisclosure(false);
    const [valueInteval, setValueInteval] = useState(30);
    const cleanTypeId = (serviceId?: string | number): number => {
        if (!serviceId) return 0
        if (typeof serviceId === "string") {
            return parseInt(serviceId.replace(/\D/g, ''), 10) || 0;
        }
        return serviceId
    };

    const quantity = watch("quantity");
    const price = useUpdatePrice(quantity || "0", cleanTypeId(product?.id));

    const { mutate: buy, isLoading, isError } = useOrdersCreateQuery()
    const onSubmit: SubmitHandler<FormProductInputs> = (data) => {
        if (data) { open() }
    };

    const onConfirm = () => {
        const { quantity, notes, ...customData } = watch();
        buy({
            service: cleanTypeId(type_id),
            period: product?.period || "", //periods[active],
            service_option: cleanTypeId(product?.id),
            custom_data: customData,
            notes: watch().notes,
            ...(valueInteval !== null ? { interval: valueInteval } : null),
            quantity: cleanTypeId(watch().quantity)
        },
            {
                onSuccess: () => {
                    toast.success('Purchase was successful');
                    closeAll();
                    close();
                },
                onError: () => {
                    toast.error('Something went wrong');
                    close();
                }
            }
        )
    }

    // const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
    // const [active, setActive] = useState(0);
    // const [controlsRefs, setControlsRefs] = useState<Record<string, HTMLButtonElement | null>>({});

    // const setControlRef = (index: number) => (node: HTMLButtonElement) => {
    //     controlsRefs[index] = node;
    //     setControlsRefs(controlsRefs);
    // };

    // const controls = periods.map((item, index) => (
    //     <UnstyledButton
    //         key={item}
    //         className={styles.control}
    //         ref={setControlRef(index)}
    //         onClick={() => setActive(index)}
    //         mod={{ active: active === index }}
    //     >
    //         <span className={styles.controlLabel}>{item}</span>
    //     </UnstyledButton>
    // ));

    const handlerUpperFirst = (field: string) => {
        return field.charAt(0).toUpperCase() + field.slice(1)
    }

    return (
        <div className={styles.product}>
            <div className={styles.category}>{product?.category}</div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                {product?.required_field?.map((field) => (
                    <div key={field} className={styles.input}>
                        <Controller
                            name={field}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: `${handlerUpperFirst(field)} required`,
                            }}
                            render={({ field: inputField }) => (
                                <Input
                                    label={handlerUpperFirst(field)}
                                    placeholder={handlerUpperFirst(field)}
                                    type={field.toLocaleLowerCase() === "email" ? "email" : "text"}
                                    required
                                    {...inputField}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors[field]?.message}</p>
                    </div>
                ))}

                <div className={styles.input}>
                    <Controller
                        name="quantity"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: `Quantity required`,
                            validate: {
                                isValidNumber: (value) => {
                                    const number = parseInt(value, 10);
                                    return number >= 1 && number <= 1000000 || "Quantity must be between 1 and 1,000,000";
                                }
                            }
                        }}
                        render={({ field }) => (
                            <Input
                                label="Quantity"
                                placeholder="Quantity"
                                type="number"
                                required
                                {...field}
                            />
                        )}
                    />
                    <p className={styles.errors}>{errors.quantity?.message}</p>
                </div>
                <div className={styles.input}>
                    <Controller
                        name="notes"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <Input
                                label="Notes"
                                placeholder="Notes"
                                type="text"
                                {...field}
                            />
                        )}
                    />
                </div>
                {
                    product?.interval === null && product.category === 'Viewers' &&
                    <>
                        <div className={styles.labelPeriod}>Interval: {valueInteval}</div>
                        <Slider value={valueInteval} onChange={setValueInteval} classNames={{
                            track: styles.sliderTrack,
                            thumb: styles.sliderThumb,
                        }}
                            min={1}
                            max={60}
                            step={1}
                        />
                    </>
                }
                {/* <div>
                    <div className={styles.labelPeriod}>Period</div>
                    <div className={styles.root} ref={setRootRef}>
                        {controls}
                        <FloatingIndicator
                            target={controlsRefs[active]}
                            parent={rootRef}
                            className={styles.indicator}
                        />
                    </div>
                </div> */}
                <br />
                {!!product?.period && (
                    <div className={styles.row}>
                        Period: {product.period}
                    </div>
                )}
                {!!product?.discount_percentage && (
                    <div className={styles.row}>
                        Discount: {product.discount_percentage}%
                    </div>
                )}
                {!!product?.price_per_unit && (
                    <div className={styles.row}>
                        Price per unit: {product.price_per_unit}$
                    </div>
                )}
                {!!product?.discounted_price && (
                    <div className={styles.row}>
                        Discounted price: {product.discounted_price}$
                    </div>
                )}
                <div className={styles.buy}>
                    <Button type="submit" content={`Buy ${!!price ? price + " $" : ""}`} />
                </div>
            </form>

            <Modal
                opened={opened}
                closeOnEscape={false}
                closeOnClickOutside={false}
                onClose={close}
                title={<div className={styles.title}>Payment</div>}
                size="sm"
                classNames={{
                    body: styles.modalBody,
                    header: styles.modalHeader,
                    content: styles.modalContent,
                    close: styles.modalCloseButton,
                    overlay: styles.modalOverlay
                }}
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <div className={styles.confirmation}>
                    <p className={styles.message}>
                        Please confirm, the payment for the service in the amount
                        of&nbsp;{!!price ? price + " $" : ""}&nbsp;will now be processed.
                    </p>
                    <div className={styles.buttons}>
                        <Button content="Confirm" onClick={onConfirm} />
                        <Button content="Cancel" onClick={close} classNames={"black"} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};
