import { queryClientBase } from 'index';
import { useMutation } from 'react-query';
import profileService, {
  ProfileEmailT,
  ProfilePasswordT,
} from 'services/profile.service';

export const useChangePasswordQuery = () => {
  return useMutation(
    async (data: ProfilePasswordT) => await profileService.password(data),
    {
      retry: false,
      onSuccess: () => {},
    }
  );
};

export const useChangeEmailQuery = () => {
  return useMutation(
    async (data: ProfileEmailT) => await profileService.email(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries('userData');
      },
    }
  );
};
