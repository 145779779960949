import React from 'react';
import styles from './Topup.module.scss';

export const Topup: React.FC = () => {
    return (
        <div className={styles.page}>
            <div className={styles.header}>
                Top Up Balance
            </div>
        </div>
    );
};
