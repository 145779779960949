import React, { useEffect } from 'react';

export const JivoChat: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `//code.jivosite.com/widget/${process.env.REACT_APP_JIVO_CODE}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

