import HttpService from './http.service';

export type MessageT = {
  id: number;
  is_active?: boolean;
  text?: string;
};

const MessageServices = {
  all: async () => {
    const { data }: { data: MessageT } = await HttpService().get(
      '/user/global-message/'
    );
    return data;
  },

  close: async (id: number) => {
    const { data }: { data: MessageT } = await HttpService().post(
      '/user/global-message/',
      { id }
    );
    return data;
  },
};

export default MessageServices;
