import HttpService from './http.service';

export type AllOrdersT = {
  completed?: string;
  created_at?: string;
  id?: number;
  period?: string;
  quantity?: number;
  service?: string;
  service_option?: string;
  status?: string;
  total_price?: string;
};

export type CrateOrderT = {
  service: number;
  service_option: number;
  custom_data: { [key: string]: string };
  quantity: number;
  period: string;
  notes?: string;
  interval?: number | null,
};

const OrdersService = {
  all: async () => {
    const { data }: { data: AllOrdersT[] } =
      await HttpService().get(`/order/all/`);
    return data;
  },

  create: async (req: CrateOrderT) => {
    const { data }: { data: any } = await HttpService().post(
      `/order/create/`,
      req
    );
    return data;
  },
};

export default OrdersService;
