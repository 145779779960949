import React, { useEffect, useMemo, useState } from 'react';
import { CustomSegmentedControl } from "components";

import styles from './Orders.module.scss';
import { useServiceCategoriesQuery, useServiceCategoriesTypeQuery } from 'lib/service/useServicesQuery';
import { IconType } from 'react-icons';
import { CardCategories } from './CardCategories';

type Props = {
    id?: string
    type?: string
}

export const Orders: React.FC<Props> = ({ id, type }) => {
    const { data: categories } = useServiceCategoriesQuery(id)

    const [selectedSegment, setSlectedSegment] = useState<string>()
    const segments = useMemo(() => {
        let list: {
            value: string;
            label: string;
            icon?: IconType;
        }[] | null = null
        if (categories?.categories?.length) {
            list = categories.categories.map((item) => {
                return ({ value: item, label: item });
            })
        }
        return list
    }, [categories])

    useEffect(() => {
        if (!segments?.length) return
        setSlectedSegment(segments[0].value)
    }, [categories])

    const { data: categoriesList } = useServiceCategoriesTypeQuery(id ?? "0", selectedSegment);

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                Add Service&nbsp;{type}
            </div>
            {
                id &&
                <div className={styles.control}>
                    {
                        selectedSegment &&
                        <CustomSegmentedControl
                            segments={segments}
                            selectedValue={selectedSegment}
                            onChange={setSlectedSegment}
                        />
                    }
                </div>
            }
            <CardCategories categories={categoriesList} type={type} type_id={id} />
        </div>
    )
}
