import { MdBuildCircle } from "react-icons/md";
import styles from './Help.module.scss';

export const Help: React.FC = () => {
    return (
        <div className={styles.page}>
            <div className={styles.header}>
                QA
            </div>
            <div className={styles.build}>
                <MdBuildCircle size={200} />
            </div>
        </div>
    )
}