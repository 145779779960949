import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button, Input } from "components";
import styles from './EmailProfile.module.scss';
import { useChangeEmailQuery } from 'lib/profile/useChangeProfileQuery';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'lib/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { toggleAuthState } from 'store/authStateSlice';
import { useAppSelector } from 'lib/useAppSelect';
import { RouteNames } from 'navigate/routeNames';
import { removeToken } from 'services';

type ChangeEmailInputs = {
    current_email: string;
    current_password: string;
    new_email: string;
}

type Props = {
    opened: boolean;
    close: () => void
}

export const EmailProfile: React.FC<Props> = ({ opened, close }) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<ChangeEmailInputs>();

    const {
        mutate: sendChangeEmail,
    } = useChangeEmailQuery();

    const handleClose = () => {
        reset();
        close();
    };

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const logInOut = () => {
        dispatch(toggleAuthState({ isLogin: false }));
        navigate(RouteNames.START);
        removeToken();
    }

    const onSubmit: SubmitHandler<ChangeEmailInputs> = (data) => {
        sendChangeEmail(data, {
            onSuccess: () => {
                toast.success("Your email has been successfully changed!");
                logInOut()
            },
            onError: (error: any) => {
                const errorMessage = error?.response?.data?.detail || 'An unexpected error occurred.';
                toast.error(errorMessage);
            }
        })
    };


    return (
        <Modal
            opened={opened}
            closeOnEscape={false}
            closeOnClickOutside={false}
            onClose={handleClose}
            title={<div className={styles.title}>
                Change Email
            </div>}
            size="sm"
            classNames={{
                body: styles.modalBody,
                header: styles.modalHeader,
                content: styles.modalContent,
                close: styles.modalCloseButton,
                overlay: styles.modalOverlay
            }}
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            <div className={styles.confirmation}>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <div>
                        <Controller
                            name="current_email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Current email is required",
                                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email" },
                            }}
                            render={({ field }) => (
                                <Input
                                    label="Current email"
                                    placeholder="Enter your current email"
                                    type="email"
                                    {...field}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors.current_email?.message}</p>
                    </div>
                    <div>
                        <Controller
                            name="current_password"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Password is required" }}
                            render={({ field }) => (
                                <Input
                                    label="Current password"
                                    placeholder="Enter your current password"
                                    type="password"
                                    {...field}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors.current_password?.message}</p>
                    </div>
                    <div>
                        <Controller
                            name="new_email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "New email is required",
                                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email" },
                                maxLength: { value: 254, message: "Email should not exceed 254 characters" },
                            }}
                            render={({ field }) => (
                                <Input
                                    label="New email"
                                    placeholder="Enter your new email"
                                    type="email"
                                    {...field}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors.new_email?.message}</p>
                    </div>
                    <div className={styles.buttons}>
                        <Button type="submit" content="Confirm" />
                        <Button type={"button"} content="Cancel" onClick={handleClose} classNames={"black"} />
                    </div>
                </form>
            </div>
        </Modal>
    )
}
