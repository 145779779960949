import { ACCESS_TOKEN, REFRESH_TOKEN } from 'consts';
import Cookies from 'js-cookie';

export const getToken = () => ({
  accessToken: Cookies.get(ACCESS_TOKEN),
  refreshToken: Cookies.get(REFRESH_TOKEN),
});
export const setToken = (access_token: string, refresh_token: string) => {
  Cookies.set(ACCESS_TOKEN, access_token, { expires: 7 });
  Cookies.set(REFRESH_TOKEN, refresh_token, { expires: 7 });
};
export const removeToken = () => {
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(REFRESH_TOKEN);
};
