import { NavLink } from 'react-router-dom';
import styles from './Header.module.scss';
import { IoLogOutOutline } from "react-icons/io5";
import { RouteNames } from 'navigate/routeNames';
import { toggleAuthState } from 'store/authStateSlice';
import { useAppDispatch } from 'lib/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { removeToken } from 'services';
import { useAppSelector } from 'lib/useAppSelect';
import { useUsersMeQuery } from 'lib/users/useUsersMeQuery';


export const Header: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const logInOut = () => {
        dispatch(toggleAuthState({ isLogin: false }));
        navigate(RouteNames.START);
        removeToken();
    }

    const { data: usersMe } = useUsersMeQuery();

    return (
        <>
            {
                isMobile ?
                    <div className={styles.headerMobile}>
                        <div className={styles.logoMobile}>
                            <NavLink to={"/"}>
                                STARKSTORE
                            </NavLink>
                        </div>
                        <div className={styles.balance}>
                            <div className={styles.card}>
                                Balance:&nbsp;&nbsp;
                                <span className={styles.many}>
                                    {usersMe?.balance}$
                                </span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.header}>
                        <div className={styles.logo}>
                            <NavLink to={"/"}>
                                STARKSTORE
                            </NavLink>
                        </div>
                        <div onClick={logInOut} className={styles.logout}>
                            Log Out <IoLogOutOutline size={22} />
                        </div>
                    </div>
            }
        </>
    )
}