import { useMutation, useQuery } from 'react-query';
import services, { CalcPriceT } from 'services/services.service';

export const useServiceListQuery = () => {
  return useQuery(['productList'], () => services.servicesList())
};


export const useServiceCategoriesQuery = (id?: string) => {
  return useQuery(
    ['productCategories', id],
    () => services.servicesCategories(id ?? "0"), {
    enabled: !!id
  }
  )
};

export const useServiceCategoriesTypeQuery = (id: string, category?: string) => {
  return useQuery(
    ['productCategoriesType', id, category],
    () => services.servicesCategoryType(id, category ?? "0"), {
    enabled: !!category
  }
  )
};


export const useCalculatePriceQuery = () => {
  return useMutation(async (data: CalcPriceT) => await services.servicesCalculatePrice(data));
};
