import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'lib/useAppSelect';
import { RouteNames } from 'navigate/routeNames';
import { useAuthQuery } from 'lib/auth/useAuthorizationQuery';
import toast from 'react-hot-toast';

export const AuthRedirectPage: React.FC = () => {
    const {
        mutate: sendAuth,
        isSuccess
    } = useAuthQuery();

    const { id, secret } = useParams<{ id: string; secret: string }>(); // Извлекаем параметры маршрута
    const isValidUrl = secret && id && window.location.pathname.includes("activate") && secret.includes("-");

    const navigate = useNavigate();
    const { isLogin } = useAppSelector((state) => state.authState);
    useEffect(() => {
        if(isSuccess) return (
            navigate(RouteNames.LOGIN)
        )
        if (isValidUrl && !isLogin) {
            if (!id || !secret) {
                navigate(RouteNames.START)
                toast.error('Something went wrong');
            } else {
                sendAuth({ uid: id, token: secret })
            }
        }
        navigate(RouteNames.LOGIN);
    }, [id, secret, isValidUrl]);

    return <></>
};
