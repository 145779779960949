import authStateReducer from './../store/authStateSlice';
import { configureStore } from '@reduxjs/toolkit';
import deviceTypeReducer from './../store/deviceTypeSlice';

const store = configureStore({
  reducer: {
    authState: authStateReducer,
    isMobile: deviceTypeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
