import React from 'react';

import styles from './OrdersTable.module.scss';
import { AllOrdersT } from 'services/orders.service';
import { formateDate } from 'utils';
import clsx from 'clsx';

type Props = {
    orders?: AllOrdersT[];
}

export const OrdersTable: React.FC<Props> = ({ orders }) => {

    return (
        <div className={styles.table}>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th className={styles.tableHeaderStyle}>Service</th>
                        <th className={styles.tableHeaderStyle}>Period</th>
                        <th className={styles.tableHeaderStyle}>Quantity</th>
                        <th className={styles.tableHeaderStyle}>Service option</th>
                        <th className={styles.tableHeaderStyle}>Status</th>
                        <th className={styles.tableHeaderStyle}>Total price</th>
                        <th className={styles.tableHeaderStyle}>Created</th>
                        <th className={styles.tableHeaderStyle}>Completed</th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders?.map((order) => (
                        <tr key={order.id} className={styles.tableRowStyle}>
                            <td className={styles.tableHeaderStyle}>{order.service}</td>
                            <td className={styles.tableHeaderStyle}>{order.period}</td>
                            <td className={styles.tableHeaderStyle}>{order.quantity}</td>
                            <td className={styles.tableHeaderStyle}>{order.service_option}</td>
                            <td className={clsx(styles.tableHeaderStyle, styles.block)}>
                                <div className={clsx(styles.status, styles[`${order.status}`])}></div>
                                <div>{order.status}</div>
                            </td>
                            <td className={styles.tableHeaderStyle}>{order.total_price}&nbsp;$</td>
                            <td className={styles.tableHeaderStyle}>{formateDate(order.created_at)}</td>
                            <td className={styles.tableHeaderStyle}>{formateDate(order.completed)}</td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    );
};
