import { queryClientBase } from 'index';
import { useMutation, useQuery } from 'react-query';
import message from 'services/message.service';

export const useServiceMessageQuery = () => {
  return useQuery(['messageList'], () => message.all());
};

export const useServiceCloseMessageQuery = () => {
  return useMutation(async (id: number) => await message.close(id), {
    retry: false,
    onSuccess: () => {
      queryClientBase.refetchQueries('messageList');
    },
  });
};
