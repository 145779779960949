import { useMutation, useQuery } from 'react-query';
import services, { CrateOrderT } from 'services/orders.service';

export const useOrdersAllQuery = () => {
  return useQuery(['ordersList'], () => services.all());
};

export const useOrdersCreateQuery = () => {
  return useMutation(async (data: CrateOrderT) => await services.create(data));
};
