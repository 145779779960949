import { queryClientBase } from 'index';
import { useAppDispatch } from 'lib/useAppDispatch';
import { RouteNames } from 'navigate/routeNames';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getToken, removeToken, setToken } from 'services';
import AuthService from 'services/auth.service';
import usersService from 'services/users.service';
import { toggleAuthState } from 'store/authStateSlice';


export const useUsersMeQuery = () => {
  const { refreshToken } = getToken();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useQuery(
    ["userData"],
    async () => await usersService.usersMe(),
    {
      enabled: !!refreshToken,
      retry: 2,
      onError: async () => {
        const data = await AuthService.refresh();
        if (!!data && refreshToken) {
          if ('access' in data) {
            setToken(data?.access, refreshToken);
            queryClientBase.refetchQueries(["userData", "productData"]);
            return;
          }
        }

        if (!refreshToken) {
          dispatch(toggleAuthState({ isLogin: false }));
          navigate(RouteNames.START);
          removeToken();
          return;
        }
      },
    }
  );
};
