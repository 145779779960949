import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import styles from './ProfileLayout.module.scss';

import { useAppSelector } from 'lib/useAppSelect';
import { SideBar, Header, ErrorFallback, Menu, JivoChat } from 'components';
import { useServiceListQuery } from 'lib/service/useServicesQuery';
import { useEffect, useMemo } from 'react';
import { useServiceMessageQuery } from 'lib/message/useServiceMessageQuery';
import { GlobalMessages } from './GlobalMessages';

type ProfileLayoutT = {
  children?: React.ReactNode;
};

export const ProfileLayout: React.FC<ProfileLayoutT> = ({ children }) => {
  const { isMobile } = useAppSelector((state) => state.isMobile);
  const { data: list } = useServiceListQuery();
  const { data: msg } = useServiceMessageQuery();


  const useViewportHeightFix = () => {
    useEffect(() => {
      const updateVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };

      updateVh();
      window.addEventListener('resize', updateVh);

      return () => window.removeEventListener('resize', updateVh);
    }, []);
  };
  
  useViewportHeightFix();
  
  const listServices = useMemo(() => {
    if (!list) return null;
    return list;
  }, [list]);

  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.body}>
        {!isMobile && <SideBar list={listServices} />}
        <div className={styles.page}>
          <div className={styles.wrapper}>
            {msg?.is_active && <GlobalMessages msg={msg} />}
            {children}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      {isMobile && <Menu />}
      <JivoChat/>
    </div>
  );
}
