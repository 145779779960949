import { MessageT } from "services/message.service"
import styles from './GlobalMessages.module.scss'
import { IoCloseSharp } from "react-icons/io5";
import { useServiceCloseMessageQuery } from "lib/message/useServiceMessageQuery";
import clsx from "clsx";

type Props = {
    msg?: MessageT
}

export const GlobalMessages: React.FC<Props> = ({ msg }) => {

    const {
        mutate: closeId,
        isLoading,
        data: messageClose,
    } = useServiceCloseMessageQuery();


    const handlerCloseMsg = (id: number) => {
        closeId(id)
    }

    return (
        <>
            {
                msg?.is_active && msg?.text && (
                    <div className={clsx(styles.message, isLoading && styles.messageClose)}>
                        {msg?.text}
                        <div className={styles.close} onClick={()=>handlerCloseMsg(msg?.id)}>
                            <IoCloseSharp size={20} />
                        </div>
                    </div>
                )
            }
        </>
    )
}