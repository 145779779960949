

import React from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { App } from './App';
import { BrowserRouter } from "react-router-dom";
import store from './reduxStore';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import '@mantine/core/styles.css';
import './assets/global.scss';

const rootElement = document.getElementById('root');

export const queryClientBase = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    },
  },
});

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement as HTMLElement);

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClientBase}>
        <MantineProvider>
          <BrowserRouter>
            <Provider store={store}>
              <App />
              <Toaster position="top-right" reverseOrder={false} />
            </Provider>
          </BrowserRouter>
        </MantineProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

