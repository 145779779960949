export enum RouteNames {
  ORDERS = '/orders',
  TOPUP = '/top-up',
  PROFILE = '/profile',
  HELP = '/help',
  LOGIN = '/login',
  NOMATCH = '*',
  ERROR = '/error',
  REGISTRATION = '/registration',
  START = "/",
}
