import { Login } from "feature";
import { PrimaryLayout } from "layouts/PrimaryLayout";

export const LoginPage: React.FC = () => {
  return (
    <PrimaryLayout>
      <Login />
    </PrimaryLayout>
  )
};
