import HttpService from './http.service';

export type ProfilePasswordT = {
  current_password: string;
  new_password: string;
};
export type ProfileEmailT = {
  current_email: string;
  current_password: string;
  new_email: string;
};

const ProfileService = {
  email: async (req: ProfileEmailT) => {
    const { data }: { data: any } = await HttpService().post(
      `/users/set_email/`,
      req
    );
    return data;
  },

  password: async (req: ProfilePasswordT) => {
    const { data }: { data: any } = await HttpService().post(
      `/users/set_password/`,
      req
    );
    return data;
  },
};

export default ProfileService;
